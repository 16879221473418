

import React, { Component, FC, RefObject } from 'react';
import { Alert, Col, Form, FormInstance, Input, Row, Typography } from 'antd';
import { inject } from 'mobx-react';
import api from 'src/api/public-api';
import type {
  OrganizationWithSnakeCaseOrgKind,
  UnifiedMembership,
} from 'src/api/public/orgs';
import ActionableBanner from 'src/components/common/actionable-banner';
import AnchorButton from 'src/components/common/anchor-button';
import AppButton from 'src/components/common/app-button';
import AppModal from 'src/components/common/app-modal';
import type { Provider } from 'src/entries/admin/providers/models';
import type FeaturesStore from 'src/stores/features-store';
import type UiStore from 'src/stores/ui-store';
import type {
  Association,
  AssociationMembership,
} from 'src/types/proto/brokers';
import type { MlsMembership } from 'src/types/proto/integrations';
import type { FetchCarApiResponseRequest } from 'src/types/proto/services/user_admin_service';
import AssociationLinkedModal from './associations-linked-modal';
import type { SharedVerificationModalProps } from './handle-membership-verification';
import classNames from 'classnames';

const clsPrefix = 'app-nrds-verify-membership-modal';

export interface NRDSVerifyMembershipModalProps
  extends SharedVerificationModalProps {
  className?: string;
  onBeforeSubmit?: (data: FormValues) => void | Promise<void>;
}

interface InjectedProps {
  ui: UiStore;
  features: FeaturesStore;
}

type Props = NRDSVerifyMembershipModalProps & InjectedProps;

type FormValues = FetchCarApiResponseRequest;

interface State extends FormValues {
  apiError: string | null;
  verified: boolean;
  visible: boolean;
}

@inject('ui', 'features')
class NRDSVerifyMembershipModal extends Component<Props, State> {
  formRef: RefObject<FormInstance<FormValues>>;

  constructor(props: Props) {
    super(props);
    this.formRef = React.createRef();

    this.state = {
      nrdsId: '',
      lastName: '',
      apiError: null,
      verified: false,
      visible: true,
    };
  }

  async componentDidMount() {
    const narCreds = await this.props.account.getNarApiCredentials();
    const { nrdsId, lastName } = narCreds;

    this.setState({
      nrdsId,
      lastName,
    });
  }

  showAssociationLinkedModal = (
    organization: OrganizationWithSnakeCaseOrgKind,
    memberships: UnifiedMembership[]
  ) => {
    const { ui } = this.props;
    const associations = (memberships ?? []).map(
      (m) =>
        ((m as AssociationMembership).association ||
          (m as MlsMembership).mlsOrg) as Provider
    );
    const additionalAssociations = associations.filter(
      (a) => a?.id !== organization.id
    );
    if (additionalAssociations.length > 0) {
      ui.setCustomModal(({ onClose }) => (
        <AssociationLinkedModal associations={associations} onClose={onClose} />
      ));
    }
  };

  verifyId = async (values: FormValues) => {
    const { nrdsId, lastName } = values;

    if (!nrdsId || !lastName) {
      return;
    }

    const {
      account,
      loadForms,
      organization,
      showAssociationsModal,
      ui,
      onVerified,
      onBeforeSubmit = () => account.setNarApiCredentials(nrdsId, lastName),
    } = this.props;
    try {
      await onBeforeSubmit(values);

      const { data: memberships } = await api.orgs.verifyNrdsId(organization);

      this.showAssociationLinkedModal(organization, memberships);

      account.refreshOwnAssociations();

      ui.toast({
        type: 'success',
        message: `Successfully added ${organization.title}`,
      });

      this.setState({
        verified: true,
      });

      if (onVerified) {
        onVerified(organization);
      }

      if (showAssociationsModal) {
        showAssociationsModal(false);
      }

      if (loadForms) {
        loadForms(true);
      }
    } catch (err: any) {
      this.setState({
        apiError: err.message || 'An unexpected error occurred.',
      });
    }
  };

  render() {
    const { className, organization, onCancel, features } = this.props;
    const { nrdsId, lastName, verified, visible } = this.state;

    const termsUrl = (organization as Association)?.termsUrl;

    return (
      <AppModal
        className={classNames(clsPrefix, className)}
        visible={visible && !verified}
        title="Verify your membership"
        cancelable
        onCancel={() =>
          this.setState({
            visible: false,
          })
        }
        footer={
          <div>
            <AppButton onClick={onCancel}>Cancel</AppButton>
            <AppButton
              type="primary"
              onClick={this.formRef.current?.submit}
              disabled={!nrdsId || !lastName}
            >
              Verify NRDS ID
            </AppButton>
            {features.enableDisplayAssociationTerms && termsUrl && (
              <div className={`${clsPrefix}__terms`}>
                <Typography.Text type="secondary">
                  By continuing you acknowledge and agree to the&nbsp;
                </Typography.Text>
                <Typography.Link href={termsUrl} target="_blank">
                  Terms of Service
                </Typography.Link>
                <Typography.Text type="secondary">
                  &nbsp;for {organization.title}
                </Typography.Text>
              </div>
            )}
          </div>
        }
      >
        {this.state.apiError && (
          <React.Fragment>
            <Alert message={this.state.apiError} type="error" />
            <br />
          </React.Fragment>
        )}
        <p>
          <strong>{organization.title}</strong> wants to verify your membership
          before providing access to their forms.
        </p>
        {organization.customVerificationMessage && (
          <div className={`${clsPrefix}__custom-verification-banner`}>
            <ActionableBanner
              className="margin-bottom"
              description={organization.customVerificationMessage}
            />
          </div>
        )}
        <Form
          onFinish={this.verifyId}
          ref={this.formRef}
          fields={[
            {
              name: ['nrdsId'],
              value: this.state.nrdsId,
            },
            {
              name: ['lastName'],
              value: this.state.lastName,
            },
          ]}
        >
          <div className={`${clsPrefix}__nrds-id`}>
            <Row>
              <Col xs={24} sm={12}>
                NRDS ID
              </Col>
              <Col className={`${clsPrefix}__nrds-id-anchor`} xs={24} sm={12}>
                <AnchorButton
                  href="https://login.connect.realtor/#!/forgotmember"
                  target="_blank"
                  underline
                  type="primary"
                >
                  How do I find my NRDS ID?
                </AnchorButton>
              </Col>
            </Row>
            <Form.Item
              name="nrdsId"
              rules={[
                {
                  min: 8,
                  max: 9,
                  message: 'NRDS ID must have 8 or 9 digits.',
                },
                {
                  pattern: /^(?:\d*)$/,
                  message: 'NRDS ID must be numeric.',
                },
              ]}
            >
              <Input
                maxLength={9}
                onChange={(e) =>
                  this.setState({
                    nrdsId: e.target.value,
                  })
                }
                data-testid="nrdsIdInput"
              />
            </Form.Item>
          </div>

          <span>Last name</span>
          <Form.Item name="lastName">
            <Input
              onChange={(e) =>
                this.setState({
                  lastName: e.target.value,
                })
              }
              data-testid="lastNameInput"
            />
          </Form.Item>
        </Form>
      </AppModal>
    );
  }
}

export default NRDSVerifyMembershipModal as unknown as FC<NRDSVerifyMembershipModalProps>;
