import React from 'react';
import api from 'src/api/public-api';
import type {
  OrganizationWithSnakeCaseOrgKind,
  UnifiedMembership,
} from 'src/api/public/orgs';
import type { Provider } from 'src/entries/admin/providers/models';
import type AccountStore from 'src/stores/account-store';
import type UiStore from 'src/stores/ui-store';
import type { AssociationMembership } from 'src/types/proto/brokers';
import type { MlsMembership } from 'src/types/proto/integrations';
import type { VerificationMethodKind } from 'src/types/proto/verifications';
import AssociationLinkedModal from './associations-linked-modal';
import BridgeVerifyMembershipModal from './bridge-verify-membership-modal';
import GARVerifyMembershipModal from './gar-verify-membership-modal';
import type { EnrichedAssociation } from './get-associations';
import NRDSVerifyMembershipModal from './nrds-verify-membership-modal';
import NwmlsVerifyMembershipModal from './nwmls-verify-membership-modal';
import SelfVerifyMembershipModal from './self-verify-membership-modal';

export interface SharedVerificationModalProps {
  account: AccountStore;
  organization: OrganizationWithSnakeCaseOrgKind;
  loadForms: (force: boolean) => void;
  onCancel: () => void;
  showAssociationsModal: (visible?: boolean) => void;
  onVerified: (org: OrganizationWithSnakeCaseOrgKind) => void;
}

interface HandleMembershipVerificationParams {
  account: AccountStore;
  ui: UiStore;
  formLibrary: EnrichedAssociation;
  onSuccess?: () => void;
}

function handleAccountIncorrect(params: HandleMembershipVerificationParams) {
  const { ui } = params;
  ui.toast({
    type: 'error',
    message: 'The credentials are not set up correctly',
  });
  showVerifyMembershipModal(params);
}

async function handleMembershipVerification(
  params: HandleMembershipVerificationParams
) {
  const { account, formLibrary } = params;

  switch (formLibrary.verificationMethod?.kind) {
    case 'NAR':
    case 'CAR': {
      const narCreds = await account.getNarApiCredentials();
      if (narCreds.nrdsId && narCreds.lastName) {
        verifyId(params);
      } else {
        handleAccountIncorrect(params);
      }
      break;
    }
    case 'NWMLS': {
      const nwmlsCreds = await account.getNwmlsApiCredentials();
      if (nwmlsCreds.nwmlsId && nwmlsCreds.lastName) {
        verifyId(params);
      } else {
        handleAccountIncorrect(params);
      }
      break;
    }
    case 'GAR': {
      showVerifyMembershipModal(params);
      break;
    }
    case 'PROVIDER_DEPENDENT':
    case 'NONE': {
      verifyId(params);
      break;
    }
    case 'BRIDGE': {
      const { verificationMethod } = params.formLibrary;
      const bridgeCreds = await account.getBridgeApiCredentials(
        verificationMethod?.bridge?.bridgeProvider
      );
      if (bridgeCreds.nrdsId && bridgeCreds.lastName) {
        verifyId(params);
      } else {
        handleAccountIncorrect(params);
      }
      break;
    }
    default:
      showVerifyMembershipModal(params);
  }
}

async function verifyId(params: HandleMembershipVerificationParams) {
  const { formLibrary, account, ui, onSuccess } = params;
  try {
    const { data: memberships } = await api.orgs.verifyNrdsId(formLibrary);
    showAssociationLinkedModal({ ...params, memberships });

    account.refreshOwnAssociations();

    ui.toast({
      type: 'success',
      message: `Successfully added ${formLibrary.title}`,
    });

    onSuccess?.();
  } catch (err) {
    ui.toast({
      type: 'error',
      message:
        (err as { message: string }).message || 'An unexpected error occurred.',
    });
    showVerifyMembershipModal(params);
  }
}

interface ShowAssociationLinkedModalParams
  extends HandleMembershipVerificationParams {
  memberships: UnifiedMembership[];
}

function showAssociationLinkedModal({
  formLibrary,
  memberships,
  ui,
}: ShowAssociationLinkedModalParams) {
  const associations = (memberships || []).map(
    (m) =>
      (m as AssociationMembership).association || (m as MlsMembership).mlsOrg
  );
  const additionalAssociations = associations.filter(
    (a) => a && a.id !== formLibrary.id
  );
  if (additionalAssociations.length > 0) {
    ui.setCustomModal(({ onClose }) => (
      <AssociationLinkedModal
        associations={associations as Provider[]}
        onClose={onClose}
      />
    ));
  }
}

function getModalComponent(kind?: VerificationMethodKind) {
  switch (kind) {
    case 'NAR':
    case 'CAR':
      // NAR & CAR share the nrdsId + lastName credentials
      return NRDSVerifyMembershipModal;
    case 'NWMLS':
      return NwmlsVerifyMembershipModal;
    case 'GAR':
      return GARVerifyMembershipModal;
    case 'BRIDGE':
      return BridgeVerifyMembershipModal;
    case 'SELF_VERIFICATION':
      return SelfVerifyMembershipModal;
    default:
      return null;
  }
}

function showVerifyMembershipModal({
  formLibrary,
  account,
  ui,
  onSuccess,
}: HandleMembershipVerificationParams) {
  const VerifyMembershipModal = getModalComponent(
    formLibrary.verificationMethod?.kind
  );

  if (!VerifyMembershipModal) {
    return false;
  }

  return ui.setCustomModal(({ onClose }: { onClose: () => void }) => (
    <VerifyMembershipModal
      account={account}
      ui={ui}
      organization={formLibrary}
      onCancel={onClose}
      loadForms={ui.forceLoadForms}
      onVerified={() => {
        onSuccess?.();
        onClose();
      }}
    />
  ));
}

export default handleMembershipVerification;
